import React from "react";
import ImgLazy from "../../components/ImgLazy";
import Layout from "../../components/Layout/Layout";
import iconCite from "../../img/icon-quote.png";
import SEO from "../../components/Seo";
import { Box, Button, Container, Image, Lead, T2 } from "../../styles/Styles";
import hero from "../../img/hero-demand-ind.png";
import Clients from "../../components/Homepage/Clients";

const benefits = [
  "需要に応じた、<br/>より良い意思決定",
  "街の一角毎の<nobr>スケール</nobr>による<nobr>気象予報</nobr>",
  "個人レベルで関係する予報に、カスタマイズした<nobr>気象予報</nobr>アラート",
  "安全アラートを<br/>ウェブとモバイルで"
];

export default () => {
  return (
    <Layout>
      <SEO
        title="オンデマンド経済 | tomorrow.io "
        image={hero}
      />

      <Container>
        <Box display="flex" flexWrap="wrap" pb={4}>
          <Box
            width={[1, 1 / 2]}
            pr={[0, 4]}
            pt={5}
          >
            <T2 as="h1" mb={3}>
              同じ天候。<br/>
              違う予報。<br/>
              新たなチャンス。
            </T2>
            <Lead mb={4}>
              無数の最新気象予報センサーがあなたの手の内に。さっそく今日からオペレーションに違い出しましょう。
            </Lead>
            <Button
              as="a"
              display={["block", "inline-block"]}
              mb={2}
              href="https://www.tomorrow.io/get-a-demo-of-hypercast/"
            >
              tomorrow.ioデモ取得
            </Button>{" "}
            <Button
              outline="1"
              as="a"
              display={["block", "inline-block"]}
              mb={2}
              href="https://www.tomorrow.io/api-signup/"
            >
              APIキー取得
            </Button>

          </Box>
          <Box width={[1, 1 / 2]} pt={2} px={3} textAlign="center">
            <Image
              maxHeight="380px"
              src={hero}
              alt=""
            />
          </Box>
        </Box>
      </Container>

      <section className="c-section py-5">
        <div className="container">
          <Clients/>
        </div>
      </section>

      <section className="c-section">
        <h3 className="h-3 text-center">ベネフィット：</h3>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-9">
              <div className="row justify-content-center">
                {benefits.map((item, index) => (
                  <div
                    key={index}
                    className="col-md-6 col-lg-3 mt-5 pr-0 pr-xl-4"
                  >
                    <div className="item-bullet" dangerouslySetInnerHTML={{__html: item}}/>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>


      <section className="c-section pb-0">
        <div className="container">
          <div className="row justify-content-center text-dark">
            <div className="col-lg-3">
              <h5 className="fw-700 lh-md">
                私たちには他の人が分からない、天候が分かります。どんな天候のときも、本当のハイパー・ローカルを手に入れよう。毎分更新する、リアルタイムの需要を予測。
              </h5>
              <div className="divider-blue my-5 w-25"/>
            </div>
            <div className="col-lg-6">
              <div className="lead fs-lg mb-5 pl-lg-4">
                <p>
                tomorrow.ioは、複数の繋がっているネットワークレイヤを利用して気象予報データを抽出し、想像を超える膨大な情報源をもとに世界の気象をマッピングします。
                </p>

                <p>
                  気象予報はオンラインマーケットプレイス、交通網、食事や食品の配達、新エコノミーでサービスを提供している、いかなる企業の需要にも影響します。
                </p>

                <p>
                tomorrow.ioの道路レベルの予報は、厳しい気象状況において、企業の自主的な行動を導くことをサポートします。都市部の各地域で、何が起こるのかを推測する代わりに、tomorrow.ioは鮮明で、詳細な気象予報を道路レベルの解像度で表示します。
                </p>

                <p>
                  私たちは、レーダーの倍の信頼がおける、分単位の短期間予測を生み出します。MicroWeatherデータの利用で、リアルタイムの需要の予測をアシスト。tomorrow.ioは、降水量の分類や、危険な気象現象が起こる、ずいぶん前にアラートを発信します。
                </p>

                <Button
                  as="a"
                  display={["block", "inline-block"]}
                  mb={2}
                  id="industry-demand-link-to-form-2"
                  href="https://www.tomorrow.io/get-a-demo-of-hypercast/"
                >
                  デモを入手
                </Button>

              </div>
            </div>
          </div>
        </div>
      </section>


      <section className="c-section pt-0">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-10 text-center pt-md-5">

              <div>
                <img className="my-5" src={iconCite} alt=""/>
                <p className="fs-lg mb-5 text-dark">
                tomorrow.ioの予報は、正確で、リアルタイムの実用的な気づきを提供してくれます。<br/>tomorrow.ioのプロダクトに大変感心し、私たちのオペレーション意思決定や、<br/>顧客に質の高いサービスを提供するのに役立っています。
                </p>
                <h6 className="h6 mb-5">
                  アリ・ラックス氏、Operations, Via
                </h6>
                <ImgLazy
                  src='https://cms2.climacell.co/wp-content/uploads/2019/02/ari-luks.png'
                  width={100}
                />
              </div>
            </div>
          </div>
        </div>
      </section>

    </Layout>
  );
};